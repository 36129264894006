import { React } from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p className="text-muted">William Wallace | Portfolio 2021</p>
    </div>
  );
};

export default Footer;
